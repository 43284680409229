<template>
  <div id="page-exchange">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.menu.exchange')
            }}</v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn small rounded @click="get" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="editItem(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="itemForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    prepend-icon="money"
                    v-model="item.currency_id"
                    :items="currencies"
                    item-text="title"
                    item-value="id"
                    :label="
                      $vuetify.lang.t('$vuetify.different.currency') + '*'
                    "
                    required
                    :rules="[rules.required]"
                    :error="!!errors.currency_id"
                    :error-messages="errors.currency_id"
                  />
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.exchange_date"
                        :label="$vuetify.lang.t('$vuetify.dates.date') + '*'"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="item.exchange_date"
                      @input="datePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-numeric"
                    autocomplete="off"
                    :label="
                      $vuetify.lang.t('$vuetify.different.rateToEuro') + '*'
                    "
                    type="number"
                    min="0"
                    required
                    counter
                    :rules="[rules.required]"
                    v-model="item.factor"
                    :error="!!errors.factor"
                    :error-messages="errors.factor"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="!valid"
            :loading="itemLoading"
            >{{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      valid: true,
      itemLoading: false,
      tableLoading: false,
      search: null,
      awaitingSearch: false,
      datePicker: false,
      item: {
        id: null,
        currency_id: null,
        factor: null,
        exchange_date: null,
        year: null,
        month: null,
        created_at: null,
        updated_at: null
      },
      emptyItem: {
        id: null,
        currency_id: null,
        factor: null,
        exchange_date: null,
        year: null,
        month: null,
        created_at: null,
        updated_at: null
      },
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        max191: (v) =>
          (v && v.length <= 191) ||
          this.$vuetify.lang.t('$vuetify.validation.max191'),
        max20Num: (v) =>
          (v && v.toString().length <= 20) ||
          this.$vuetify.lang.t('$vuetify.validation.max191')
      },
      totalItems: 0,
      options: {},
      items: [],
      currencies: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      errors: {
        currency_id: null,
        factor: null,
        exchange_date: null
      },
      defaultErrors: {
        currency_id: null,
        factor: null,
        exchange_date: null
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.menu.exchange')
    }
  },
  watch: {
    dialog: function(val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
        this.dialog = null
        this.errors = Object.assign({}, this.defaultErrors)
      }
    },
    dialogDelete: function(val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    search(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.get()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    item: {
      handler() {
        this.validation()
      },
      deep: true
    },
    options: {
      handler() {
        this.get()
      },
      deep: true
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    formTitle() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.edit')
        : this.$vuetify.lang.t('$vuetify.buttons.new')
    },
    compAction() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    },
    compHeaders() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.date'),
          value: 'exchange_date'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.currency'),
          value: 'currency_title'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.rateToEuro'),
          value: 'factor'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    editItem(item) {
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.item = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.itemLoading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/exchange/destroy',
          { id: this.item.id },
          this.compAuthHeader
        )
        .then((response) => {
          this.itemLoading = false
          if (response.status === 200) {
            this.get()
            this.dialogDelete = false

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t(
              '$vuetify.messages.deleted'
            )
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.itemLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    save() {
      let t = this
      this.errors = Object.assign({}, this.defaultErrors)

      if (this.$refs.itemForm.validate()) {
        let url = 'api/exchange/store'
        if (this.item.id) url = 'api/exchange/update'
        this.itemLoading = true

        this.$axios
          .post(
            this.$store.getters.getApiUrl + url,
            this.item,
            this.compAuthHeader
          )
          .then((response) => {
            this.itemLoading = false
            if (response.status === 200) {
              this.get()
              this.dialog = false

              this.snackbar.show = true
              this.snackbar.color = 'green'
              this.snackbar.text = this.$vuetify.lang.t(
                '$vuetify.messages.saved'
              )
            } else if (
              response.status === 422 &&
              typeof response.data.errors !== 'undefined'
            ) {
              for (let key in response.data.errors) {
                response.data.errors[key].forEach(function(item) {
                  t.errors[key] = item
                })
              }
            } else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch((error) => {
            this.itemLoading = false
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          })
      }
    },
    get() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0]
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/exchange/index',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }
          this.tableLoading = false
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    init() {
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/exchange/init',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.currencies = response.data.currencies
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    validation() {
      for (let keyItem in this.item) {
        if (this.errors[keyItem]) this.errors[keyItem] = null
      }
    }
  }
}
</script>
